import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/index"
import Seo from "../components/seo"

//Page content
import LandingContent from "../components/landing"
import Brochure from "../components/brochure"

const TransactionServicesPage = (props) => {

  const data = useStaticQuery(graphql`
  query TransactionServicesQuery {
    contentfulPage(slug: {eq: "/transaction-services/"}) {
      title
      description {
        description
      }
      keywords
      pageContent {
        ... on ContentfulCommercialBrochure {
          __typename
          id
          commercialBrochureLink {
            contentful_id
            file {
              url
            }
          }
          commercialTagline
        }
        ... on ContentfulLandingContent {
            __typename
            id
            title
            useCaseSectionTitle
            keyFigures
            featuredSkills
            poleDescription {
              file {
                url
                fileName
                contentType
              }
            }
            description {
                childMarkdownRemark {
                    html
                }
            }
            clientsLogo {
                    id
                    title
                    description
                    fluid(maxWidth: 150) {
                      ...GatsbyContentfulFluid_noBase64
                  }
            }
            useCasesList {
                id
                title
                description {
                    childMarkdownRemark {
                        html
                    }
                }
            }
          }
      }
    }
  }
  `)

  const commercialBrochure = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulCommercialBrochure");
  const landingContent = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulLandingContent");

  return (
    <Layout>
      <Seo
        title={data.contentfulPage.title}
        description={data.contentfulPage.description.description}
        keywords={data.contentfulPage.keywords} />
      <LandingContent content={landingContent} />
      <Brochure brochure={commercialBrochure} />
    </Layout>
  )
}

export default TransactionServicesPage